/* Global Styles */
*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 80vmin;
}

body {
  color: #5d5d5d;
  background: #fff;
  font-family: 'Open Sans', 'Helvetica', 'sans-serif';
}

a {
  text-decoration: none;
  color: inherit;
}

/* JV Logo */
.jv-logo {
  height: 120px;
  transform: translate(-15px, -5px);
}

/* React Logo */
.react-logo {
  height: 80px;
  pointer-events: none;
}

@media only screen and (max-width: 800px) {
  .react-logo {
    height: 70px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .react-logo {
    animation: react-logo-spin infinite 10s linear;
  }
}

@keyframes react-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
