@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Work+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&display=swap);
/* Global Styles */
*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 80vmin;
}

body {
  color: #5d5d5d;
  background: #fff;
  font-family: 'Open Sans', 'Helvetica', 'sans-serif';
}

a {
  text-decoration: none;
  color: inherit;
}

/* JV Logo */
.jv-logo {
  height: 120px;
  -webkit-transform: translate(-15px, -5px);
          transform: translate(-15px, -5px);
}

/* React Logo */
.react-logo {
  height: 80px;
  pointer-events: none;
}

@media only screen and (max-width: 800px) {
  .react-logo {
    height: 70px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .react-logo {
    -webkit-animation: react-logo-spin infinite 10s linear;
            animation: react-logo-spin infinite 10s linear;
  }
}

@-webkit-keyframes react-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes react-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

